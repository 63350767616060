@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap&subset=latin-ext');

html, body {
    width: 100%;
    height: 100%;
    background: #ededef;
    font-family: 'Open Sans', sans-serif;
}





