header#navbar {
    background: #fff;
    max-width: none;
    border-radius: 0;
    margin-bottom: 0;
    border: 0;
    margin-top: 0;
}

.navigation-bg {
    background: #fff;  
}

@media (min-width: 768px) {
    .navbar-nav {
        float: right;
        margin: 0;
    }
}


.view-main-vijest {
    .item {
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: -webkit-gradient(linear,left top,left bottom,color-stop(50%,transparent),to(#111));
            background-image: linear-gradient(180deg,transparent 50%,#111 100%);
            pointer-events: none;
        }
    }
    .views-field-title {
        position: absolute;
        bottom: 70px;
        left: 40px;
        a {
            color: #FFF;
            font-size: 25px;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @media screen and (min-width: 768px) {
        .carousel-indicators {
            bottom: -6px;
        }
    }

}

.region-main-text, .region-right-text {
    border-radius: 3px;
    background: #fff;
    position: relative;
    min-height: 300px;
    width: 100%;
    margin-bottom: 1rem;
    -webkit-transition: -webkit-box-shadow .2s ease-out;
    transition: box-shadow .2s ease-out;
    transition: box-shadow .2s ease-out,-webkit-box-shadow .2s ease-out;
    margin-top: 26px;
    padding: 20px 35px;
    ul {
        padding-left: 0;
        li {
            padding-bottom: 10px;
        }
    }
    h2.block-title {
        margin-top: 0;
        font-size: 35px;
        font-weight: 700;
        color: #293340;
    }
    p {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.666;
        color: #293340;
    }
    a {
        display: block;
        color: #293340;
        text-transform: uppercase;
        transition: 0.5s  ease-out;
        &:before {
            height: 0;
            width: 20px;
            border-top: 2px solid #343c48;
            content: '';
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
            font-weight: 700;
            transition: 0.5s ease-out;
        }
        &:hover {
            text-decoration: none;
            &:before {
                margin-right: 15px;
            }
        }
    }
}

h2.comments__form-title,
form#comment-form, .comment-add {
  display: none !important;
}

.field-name-body {
    p {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.666;
        color: #293340;
    }
}

h2.title {
    margin-top: 0;
    font-size: 35px;
    font-weight: 700;
    color: #293340;
}

.region.region-content {
    background: #FFF;
    margin-top: 15px;
}
.content-filed {
    padding: 20px;
}

.footer {
    border: 0;
    background: #293340;
    text-align: center;
    padding: 30px 21px;
    color: #FFF;
    margin-top: 0;
}

.gm-style .place-card-large {
    display: none !important;
}

.mapa {
    position: relative;
    margin-top: 20px;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background: none;
}

.navbar-default .navbar-nav > .active > a {
    background: none;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    color: #293340;
    text-decoration: none;
    background: none;
    outline: 0;
}


.mapa-holder {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
}

.mapa-box {
    max-width: 280px;
    background: #FFF;
    padding: 20px;
    color: #293340;
    border-radius: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    h2 {
        margin-top: 0;
    }
    p {
        margin-bottom: 8px;
    }
}

.view-vijesti {
    padding: 20px;
    padding-top: 0;
    .views-field-title {
        a {
            font-size: 25px;
            font-weight: 700;
            color: #2a3340;
        }
    }
    .views-row {
        border-bottom: 1px solid lighten(#2a3340, 50%);
        margin-bottom: 10px;
        padding-bottom: 10px;
        &:last-child {
            border: 0;
        }
    }
}

.navbar .logo {
    width: 170px;
    img {
        max-width: 100%;
    }
}